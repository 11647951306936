import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({ meta }) => {
  const { title, description, isSmallImage, uri } = meta;

  const baseUrl = 'https://mindrommelinje.no/';

  const squareImage = 'images/min_drommelinje_kvadratisk.png';
  // const squareGif = 'images/min_drommelinje_kvadratisk.gif';

  const largeImage = 'images/min_drommelinje_some_shared_link_format_1.png';
  // const largeImageAlternative = 'images/min_drommelinje_some_shared_link_format_2.png';
  // const largeGif = 'images/min_drommelinje_some_shared_link_format.gif';

  const ogImage = isSmallImage
    ? `${baseUrl}${squareImage}`
    : `${baseUrl}${largeImage}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      {isSmallImage && <meta property="og:image" content="smallImageUrl" />}
      <link rel="canonical" href={`${baseUrl}${uri ? uri : ''}`} />
      <meta property="og:url" content={`${baseUrl}${uri ? uri : ''}`} />
      <meta property="og:image" content={ogImage} />
      <meta
        property="og:image:width"
        content={isSmallImage ? '1080' : '1200'}
      />
      <meta
        property="og:image:height"
        content={isSmallImage ? '1080' : '628'}
      />
      <meta name="twitter:title" content="Min Drømmelinje" />
      <meta name="twitter:type" content="website" />
      <meta name="twitter:image" content={ogImage} />
      <meta
        name="twitter:image:width"
        content={isSmallImage ? '1080' : '1200'}
      />
      <meta
        name="twitter:image:height"
        content={isSmallImage ? '1080' : '628'}
      />
    </Helmet>
  );
};

SEO.defaultProps = {
  meta: {
    title: 'Min Drømmelinje',
    description:
      'Hva er din drømmelinjen på folkehøgskole? Fortell oss hvilken folkehøgskolelinje du drømmer om.',
    isSmallImage: false,
    uri: null,
  },
};

SEO.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    isSmallImage: PropTypes.bool,
    uri: PropTypes.string,
  }).isRequired,
};

export default SEO;
