import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import FrontPage from './pages/index.js';
// import ArticlePage from './pages/Article.js';

function App() {
  return (
    <Router>
      <Switch>
        <Route component={FrontPage} path="/" exact />
        <Route component={FrontPage} path="/:suggestionId?" exact />
      </Switch>
    </Router>
  );
}

export default App;
