import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import sanityClient from '../client.js';
import SEO from '../components/SEO';
//import SuggestionForm from '../components/SuggestionForm';
import SuggestionsList from '../components/SuggestionsList';
import SuggestionView from '../components/SuggestionView';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TitleSection from '../components/TitleSection';
//import Rules from '../components/Rules';
import useLocalStorage from '../hooks/useLocalStorage.js';

export default function Frontpage() {
  // Similar to useState but first arg is key to the value in local storage.
  const [suggestionsData, setSuggestionsData] = useLocalStorage(
    'suggestionsData1',
    []
  );
  const [categoriesData, setCategories] = useState([]);
  const { suggestionId } = useParams();

  const mergeFreshData = ({ oldData, newData }) => {
    const hasOldData = oldData.length > 0;
    const newDataWithMatches = newData?.map((item) => {
      const newId = item._id;
      if (hasOldData) {
        const oldDataMatch = oldData.find((f) => f._id === newId);
        return {
          ...oldDataMatch,
          ...item,
        };
      }
    });
    return hasOldData ? newDataWithMatches : newData;
  };

  const fetchCategoriesData = () => {
    sanityClient
      .fetch(`*[!(_id in path('drafts.**')) && _type == "category"]`)
      .then((data) => setCategories(data))
      .catch(console.error);
  };

  const fetchSuggestionsData = () => {
    sanityClient
      .fetch(
        `
        *[!(_id in path('drafts.**')) && _type == "suggestion"]{_id, _createdAt, votes, _type, title, firstName, description, categories }
        `
      )
      .then((data) => {
        const newData = mergeFreshData({
          // 'oldData' is suggestionData, which is based on 'localStorage'
          oldData: suggestionsData,
          // 'newData' is the fresh data we're fetching, which will be merged into the old data
          newData: data,
        });
        setSuggestionsData(newData);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (!categoriesData.length) {
      fetchCategoriesData();
    }
    fetchSuggestionsData();
  }, []);

  const suggestion = suggestionsData.find((s) => s._id === suggestionId) || {};
  const notEmptyStuggestion =
    Object.keys(suggestion).length > 0 && suggestion.constructor === Object;
  const suggestions = suggestionsData || [];

  const onVote = (_id) => {
    const newSuggestions = [];

    for (var i = 0; i < suggestions.length; i++) {
      const suggestion = suggestions[i];
      if (suggestion._id === _id) {
        suggestion.voted = true;
        suggestion.votes = suggestion.votes + 1;
      }
      newSuggestions.push(suggestion);
    }
    setSuggestionsData(newSuggestions);
  };

  const { title, description } = suggestion;

  const pageSEO = {
    title: title ? `${title} - Min Drømmelinje` : null,
    description,
    isSmallImage: notEmptyStuggestion,
    uri: suggestionId && `${suggestionId}/`,
  };

  return (
    <>
      <SEO meta={pageSEO} />
      {notEmptyStuggestion && <div className="hidden md:block overlay" />}
      <Header />
      <main className="px-4 md:px-16 xl:px-32 2xl:px-48">
        <TitleSection />
        {/*comment out to deactivate voting 
        <SuggestionForm categories={categoriesData || []} />
        */}
        {notEmptyStuggestion && (
          <SuggestionView suggestion={suggestion} onVote={onVote} />
        )}
        <SuggestionsList
          suggestions={suggestions || []}
          categories={categoriesData || []}
          onVote={onVote}
        />
        {/* comment out to deactivate voting
        <Rules></Rules>
        */}
      </main>
      <Footer />
    </>
  );
}

Frontpage.propTypes = {
  suggestionId: PropTypes.string,
};

Frontpage.defaultProps = {
  suggestionId: '',
};
