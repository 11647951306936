/* eslint-disable no-undef */
export const config = {
  sanity: {
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: process.env.REACT_APP_SANITY_DATASET,
    token: process.env.REACT_APP_SANITY_TOKEN,
  },
  recaptcha: {
    siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  },
};
