import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import sanityClient from '../../client.js';
import heart from '../../assets/heart.svg';
import ShareButtons from '../ShareButtons';
import close from '../../assets/close.svg';
import './styles.css';

export default function SuggestionView({ suggestion, onVote }) {
  if (!suggestion) {
    return '';
  }

  const {
    _id = '',
    title = '',
    description = '',
    votes = 0,
    firstName,
    voted,
  } = suggestion;

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    onVote(_id);
    sanityClient
      .patch(_id) // Document ID to patch
      .inc({ votes: 1 })
      .commit()
      .then(() => {
        setIsLoading(false);
        // console.log(`Suggestion was updated, document ID is ${res._id}`);
      })
      .catch(() => {
        setIsLoading(false);
        console.error;
      });
  };

  return (
    <div className="suggestion-view block pt-8 pb-10 px-10 bg-white shadow">
      <Link
        to="/"
        className="block w-fit-content ml-auto mb-5 no-underline text-black"
      >
        <div className="flex block">
          <span>Lukk</span>
          <img className="pl-2" src={close} alt="hjerte" />
        </div>
      </Link>
      <div className="flex mb-2">
        <h3 className="block flex-grow large-h3 my-auto">{title}</h3>
        <div className="flex my-auto">
          <p className="mr-1.5 my-auto text-small-gray">{votes}</p>
          <img src={heart} alt="hjerte" className="block" />
        </div>
      </div>
      <p className="block mb-2 min-h-32">{description}</p>

      <div className="flex mt-5 justify-between content-center align-center flex-column-sm">
        <div className="lg:self-center md:self-center sm:self-center sm:self-start">
          <p>{`Sendt inn av ${firstName}`}</p>
        </div>
        <div className="flex content-center py-5-sm self-center-sm">
          <p className="inline self-center">Del forslaget &nbsp;</p>
          <ShareButtons quote={{ title, description }}></ShareButtons>
        </div>
        <button
          onClick={onSubmit}
          disabled={isLoading || voted}
          className={`button-blue block my-auto self-center-sm ${
            suggestion.voted ? 'disabled' : 'disabled'
          }`}
        >
          {/*comment out to deactivate voting 
          {voted ? 'Takk!' : 'Stem'}
          */}
        </button>
      </div>
    </div>
  );
}

SuggestionView.propTypes = {
  suggestion: PropTypes.object.isRequired,
  onVote: PropTypes.func,
};
