import React from 'react';
import logoClean from '../../assets/logoClean.svg';
import facebookIcon from '../../assets/facebookIcon.svg';
import instagramIcon from '../../assets/instagramIcon.svg';
import twitterIcon from '../../assets/twitterIcon.svg';
import ytIcon from '../../assets/ytIcon.svg';

export default function Footer() {
  return (
    <footer className="block px-4 md:px-16 xl:px-32 2xl:px-48 mt-20">
      <div className="flex mb-10 border-top-red">
        <div className="hidden md:block w-1/3"></div>
        <div className="block px-8 md:px-20 lg:px-20 pt-20 pb-28 2xl:pl-0">
          <img src={logoClean} alt="folkehøgskolene" className="block mb-10" />
          <p className="block mb-10">
            Folkehøgskolene driver informasjons- og kommunikasjonsarbeid på
            vegne av alle de 84 folkehøgskoler i Norge.
          </p>
          <p className="block mb-10">
            På <a href="https://www.folkehogskole.no/">folkehogskole.no</a> kan
            du finne skolen som passer for deg - og søke om studieplass.
          </p>
          <div className="flex">
            <a
              href="https://www.facebook.com/folkehogskolene"
              className="block mr-5"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebookIcon} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/folkehogskolene/"
              className="block mr-5"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagramIcon} alt="instagram" />
            </a>
            <a
              href="https://twitter.com/folkehogskolene"
              className="block mr-5"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterIcon} alt="twitter" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCE2JdVPGzPmuRDT0M9riKCg"
              className="block"
              target="_blank"
              rel="noreferrer"
            >
              <img src={ytIcon} alt="youtube" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {};
