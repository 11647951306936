import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

export default function PrizeCard({ text }) {
  return (
    <div className="prizecard flex">
      <p className="text-center pt-4 pb-5 px-5 m-auto">{text}</p>
    </div>
  );
}

PrizeCard.propTypes = {
  text: PropTypes.string,
};

PrizeCard.defaultProps = {
  text: 'Vinn en MacBook!',
};
