import React, { useEffect, useState } from 'react';
import PrizeCard from '../PrizeCard';

const WORDS = [
  ['Min', 'Vår', 'Alles', 'Hens', 'Deres'],
  ['drømme', 'viking', 'frilufts', 'musikk', 'multi', 'hiphop', 'bil', 'natur'],
  ['linje', 'design', 'studio', 'dans', 'liv', 'sport', 'miljø', 'medier'],
];

const getRandomWord = (wordNumber) => {
  if (wordNumber > WORDS.length) {
    throw new Error(`Word #${wordNumber} not supported!`);
  }

  const wordBucket = WORDS[wordNumber - 1]; // WORDS is 0-based
  const randomWord = wordBucket[Math.floor(Math.random() * wordBucket.length)];

  if (!randomWord) {
    throw new Error(`No word defined for index: ${wordNumber}`);
  }
  return randomWord;
};

function useRandomWord(wordNumber) {
  const [word, setWord] = useState(getRandomWord(wordNumber));

  useEffect(() => {
    const interval = setInterval(() => {
      setWord(getRandomWord(wordNumber));
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, [wordNumber]);

  return word;
}

export default function TitleSection() {
  const word1 = useRandomWord(1);
  const word2 = useRandomWord(2);
  const word3 = useRandomWord(3);

  return (
    <section className="block mb-20 lg:mb-28">
      <h1 className="hidden">Min drømmelinje</h1>
      <div className="flex mb-10">
        <div className="hidden md:block w-1/3" />
        <p className="block  xl:w-7/12 lg:w-7/12 md:w-7/12 sm:w-full sm:text-lg md:text-2xl lg:text-3xl font-bold">
          Fortell oss hvilken folkehøgskolelinje du drømmer om – og gi den en
          sjanse til å bli virkelig.
        </p>
      </div>
      <div className="block lg:flex mb-10">
        <div className="block w-full lg:w-1/3">
          <p className="text-huge text-light-red">{word1}</p>
        </div>
        <div className="block w-full lg:w-2/3">
          <p className="block lg:inline text-huge text-light-purple lg:mr-5">
            {word2}
            <span className="inline lg:hidden">-</span>
          </p>
          <p className="block lg:inline text-huge text-light-blue">{word3}</p>
        </div>
      </div>
      <div className="flex">
        <div className="hidden md:block w-1/3">
          <PrizeCard />
        </div>
        <div className="block lg:w-7/12 md:w-7/12 sm:w-full">
          <p className="md:text-2xl sm:text-xl mb-5">
            <strong>
              Nå kan drømmelinjen din bli virkelig på en av de 84
              folkehøgskolene i Norge.
            </strong>
          </p>
          <ol className="md:text-2xl ml-1.5" role="list">
            <li className="mb-5 pl-2" role="listitem">
              Fortell oss hva du drømmer om å lære mer om. Instapoesi, bitcoins,
              økomote eller kanskje aliens? Vi vil vite hva du brenner for!
            </li>
            <li className="mb-5 pl-2" role="listitem">
              Få folk til å stemme på forslaget ditt for å øke vinnersjansene
              dine.
            </li>
            <li className="mb-5 pl-2" role="listitem">
              Få beskjed om vinnerne på e-post.
            </li>
          </ol>
          <p className="md:text-2xl mb-5">
            En jury trekker 3 vinnere blant de 10 forslagene med flest stemmer,
            og vinnerne får en Mac verdt 15 000 kr.
          </p>
          <p className="md:text-2xl">
            {/*change text while voting deactivated */}
            <strong>Konkurransen er avsluttet, takk for alle bidrag!</strong>
          </p>
          <p className="md:text-2xl">
            <a
              href="https://www.folkehogskole.no/blogg/over-500-forslag-til-nye-drommelinjer-pa-folkehogskole"
              className="md:text-2xl"
            >
              Her kan du se hvem som vant
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
