import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import sanityClient from '../../client.js';
import { config } from '../../config.js';
import injectJS from '../../injectJs.js';
import categoryTailwindMapper from '../../categoryTailwindMapper';
import heart from '../../assets/heart.svg';
import './styles.css';

export default function SuggestionCard({ suggestion, onVote }) {
  const {
    _id = '',
    title = '',
    description = '',
    firstName = '',
    votes = 0,
    categories = [],
  } = suggestion;

  const [isLoading, setIsLoading] = useState(false);

  let btnRef = useRef();

  // eslint-disable-next-line no-unused-vars
  const validate = (e) => {
    // TODO: FIX THIS
    e.preventDefault();
    injectJS(
      `https://www.google.com/recaptcha/api.js?render=${config.recaptcha.siteKey}`,
      function () {
        // console.log(window.grecaptcha);
        window.grecaptcha.execute(config.recaptcha.siteKey);
        // .then(function (token) {
        //   console.log('v3 Token: ' + token);
        // });
      }
    );
  };

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    onVote(_id);
    if (btnRef.current) {
      btnRef.current.setAttribute('disabled', 'disabled');
      btnRef.current.classList.add('disabled');
    }
    sanityClient
      .patch(_id) // Document ID to patch
      .inc({ votes: 1 })
      .commit()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        console.error;
      });
  };

  const checkIfMobile = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      window.scrollTo(0, 1100);
    }
  };

  let borderColor = 'blue';
  if (categories.length > 0) {
    borderColor = categoryTailwindMapper(null, categories[0]._ref);
  }
  return (
    <div className={`block p-10 border-top-${borderColor} bg-white shadow`}>
      <div className="flex mb-2">
        <h3 className="block flex-grow large-h3 my-auto">{title}</h3>
        <div className="flex my-auto">
          <p className="mr-1.5 my-auto text-small-gray">{votes}</p>
          <img src={heart} alt="hjerte" className="block" />
        </div>
      </div>
      <p className="card-description block h-32 mb-2">{description}</p>
      <Link to={`/${_id}`} className="text-black" id="readMore">
        <span onClick={checkIfMobile}>Les mer</span>
      </Link>
      <div
        id="recaptcha"
        className="g-recaptcha"
        data-sitekey={config.recaptcha.siteKey}
        data-callback="onSubmit"
        data-size="invisible"
      ></div>
      <div className="flex mt-5">
        <p className="flex-grow my-auto pr-5 overflow-hidden overflow-ellipsis whitespace-nowrap">{`Sendt inn av ${firstName}`}</p>
        <button
          onClick={onSubmit}
          className={`button-blue block my-auto ${
            suggestion.voted ? 'disabled' : 'disabled'
          }`}
          disabled={isLoading || suggestion.voted}
          ref={btnRef}
        >
          {/*comment out to deactivate voting 
          {voted ? 'Takk!' : 'Stem'}
          */}
        </button>
      </div>
    </div>
  );
}

SuggestionCard.propTypes = {
  suggestion: PropTypes.object.isRequired,
  onVote: PropTypes.func,
};
