import React from 'react';
import categoryTailwindMapper from '../../categoryTailwindMapper';
import PropTypes from 'prop-types';

export default function Select({
  options,
  selectRef,
  value,
  onChange,
  labelText,
  labelAbove,
  name,
  classes,
  labelClasses,
  errorMessage,
  filterValue,
}) {
  return (
    <div className={classes}>
      <label
        htmlFor={name}
        className={`${
          labelAbove ? 'block mb-2 ' : 'inline mr-4 '
        }${labelClasses}`}
      >
        {labelText}
      </label>
      {errorMessage && (
        <p className="block validation-error">{`*${errorMessage}`}</p>
      )}
      <select
        id={name}
        name={name}
        ref={selectRef}
        value={value}
        onChange={onChange}
        className={`${labelAbove ? 'w-2/3 ' : ''} border-bottom-${
          filterValue ? categoryTailwindMapper(null, filterValue) : 'blue'
        } px-1 py-1`}
      >
        {options.map((opt, index) => (
          <option key={`${opt.value}-${index}`} value={opt.value}>
            {opt.title}
          </option>
        ))}
      </select>
    </div>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  selectRef: PropTypes.func,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  classes: PropTypes.string,
  onChange: PropTypes.func,
  labelAbove: PropTypes.bool,
  labelClasses: PropTypes.string,
  errorMessage: PropTypes.string,
  filterValue: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  classes: '',
  labelClasses: '',
  labelAbove: false,
  errorMessage: '',
  filterValue: 'blue',
};
