import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import SuggestionCard from '../SuggestionCard';
import Select from '../Select';
import categoryTailwindMapper from '../../categoryTailwindMapper';

const PER_PAGE = 9;
const allValuesObj = { title: 'Alle', value: 'all' };

export default function SuggestionsList({ suggestions, categories, onVote }) {
  const [filterValue, setFilterValue] = useState(allValuesObj.value);
  const [sorting, setSorting] = useState('default'); // or popularity
  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
  const [suggestionsCount, setSuggestionsCount] = useState(
    Math.min(suggestions.length, PER_PAGE)
  );

  const sliceSuggestions = useCallback(() => {
    const newFilteredSuggestions =
      filterValue === allValuesObj.value
        ? suggestions
        : suggestions.filter((suggestion) => {
            return suggestion.categories.find((c) => c._ref === filterValue);
          });

    const cards = newFilteredSuggestions
      .sort(function (a, b) {
        if (sorting === 'default') {
          return new Date(b._createdAt) - new Date(a._createdAt);
        } else if (sorting === 'popularity') {
          return b.votes - a.votes;
        }
        return false;
      })
      .slice(0, suggestionsCount);

    setFilteredSuggestions(cards);
  }, [filterValue, sorting, suggestions, suggestionsCount]);

  useEffect(() => {
    setSuggestionsCount(Math.min(suggestions.length, PER_PAGE));
  }, [suggestions.length]);

  useEffect(() => {
    sliceSuggestions();
  }, [sliceSuggestions, filterValue]);

  const handleExpandClick = (e) => {
    e.preventDefault();

    setSuggestionsCount(
      Math.min(suggestions.length, suggestionsCount + PER_PAGE)
    );
  };

  const categoryOptions = (categories || []).map((c) => ({
    title: c.title,
    value: c._id,
    number: c.number,
    color: categoryTailwindMapper(c.number),
  }));
  const filterOptions = [allValuesObj].concat(categoryOptions);
  const filterOptionsSorted = filterOptions.sort(function (a, b) {
    return a.number - b.number;
  });
  function checkSort(evt) {
    if (evt.target.value === 'Nyeste bidrag') {
      setSorting('default');
    } else if (evt.target.value === 'Mest populært') {
      setSorting('popularity');
    }
  }
  return (
    <section>
      {/* comment out to deactivate voting
       <h2>Stem på forslag</h2> 
      */}
      <h2>Forslagene som kom inn i 2021</h2>
      <div className="flex flex-column">
        <Select
          name="categoryFilter"
          labelText="Vis kategori"
          classes="block my-5"
          filterValue={filterValue}
          options={filterOptionsSorted}
          onChange={(e) => {
            e.preventDefault();
            setFilterValue(e.target.value);
            setSuggestionsCount(Math.min(suggestions.length, PER_PAGE));
          }}
        />
        <div className="block my-5 md:ml-10">
          <p className="block lg:ml-4 md:ml-2 lg:text-xl md:text-lg font-bold inline mr-4 ">
            Sorter på
          </p>
          <select
            className="border-bottom-blue px-1 py-1 select-sort"
            onChange={() => checkSort(event)}
          >
            <option>Nyeste bidrag</option>
            <option>Mest populært</option>
          </select>
        </div>
      </div>

      <div className="flex flex-wrap -ml-2 -mr-2">
        {filteredSuggestions.map((suggestion, index) => (
          <div
            key={`${index}-${suggestion._id}`}
            className="p-3 w-full md:w-1/2 lg:w-1/3"
          >
            <SuggestionCard suggestion={suggestion} onVote={onVote} />
          </div>
        ))}
      </div>

      {suggestionsCount !== suggestions.length && (
        <div className="flex">
          <button
            className="button-secondary block mx-auto mt-10"
            onClick={handleExpandClick}
          >
            Vis flere forslag
          </button>
        </div>
      )}
    </section>
  );
}

SuggestionsList.propTypes = {
  suggestions: PropTypes.array,
  categories: PropTypes.array,
  onVote: PropTypes.func,
};

SuggestionsList.defaultProps = {
  suggestions: [],
  categories: [],
  onVote: () => {},
};
