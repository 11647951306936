// Quickfix untill someone with sanity access can add colors into sanity.
const categoryTailwindMapper = (color, id) => {
  const categoryBy = color || id;

  switch (categoryBy) {
    case 1:
    case 'c0a90d80-1f7f-43f2-b47e-7de857e1c760':
      return 'cat-1'; // Friluftsliv
    case 2:
    case '70700353-e653-4780-92aa-8084f9d55c22':
      return 'cat-2'; // Reise
    case 3:
    case 'd941343e-55d1-418e-b75a-290bcbbd5b71':
      return 'cat-3'; // Solidaritet
    case 4:
    case 'c2b92aa8-aae1-4410-b79b-a00951cd2412':
      return 'cat-4'; // Media
    case 5:
    case '5aac5c72-94bd-497e-a095-6661d44379ba':
      return 'cat-5'; // Musikk
    case 6:
    case '686bf9af-6be8-44a4-9862-1aafb66183ae':
      return 'cat-6'; // Kunst
    case 7:
    case '36e9513d-fd50-4dda-ae4c-da13bac002b6':
      return 'cat-7'; // Andre fag
    case 8:
      return 'cat-8'; // Tillrettelagde tillbud
    default:
      return 'blue';
  }
};

export default categoryTailwindMapper;
