import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';
//import './styles.css';

export default function ShareButtons({ quote }) {
  const url = window.location.href;
  const { title, description } = quote;
  const includedTitle = `${title}${description ? ` - ${description}` : ''}`;
  const size = '32px';
  return (
    <div className="inline flex self-center">
      <FacebookShareButton url={url} quote={includedTitle}>
        <FacebookIcon size={size} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={includedTitle}>
        <TwitterIcon size={size} round={true} />
      </TwitterShareButton>

      <EmailShareButton url={url} subject={title} body={description}>
        <EmailIcon size={size} round={true} />
      </EmailShareButton>
    </div>
  );
}

ShareButtons.defaultProps = {
  quote: {
    title: null,
    description: null,
  },
};

ShareButtons.propTypes = {
  quote: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};
