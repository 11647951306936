import React from 'react';
import logoRed from '../../assets/logoRed.svg';

export default function Header() {
  return (
    <header className="flex h-14 md:mt-4 mb-10 md:mb-20">
      <div className="block flex-grow">
        <img src={logoRed} alt="folkehøgskolene" />
      </div>
      <a
        href="https://www.folkehogskole.no/"
        className="hidden md:block mr-10 my-auto blue-link"
      >
        Til folkehogskole.no →
      </a>
    </header>
  );
}

Header.propTypes = {};
